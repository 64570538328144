<template>
    <div>
        <div v-if="currentStudent">
            <div class="card">
                <h1 :class="(isArchived()) ? 'text-muted' : '' ">
                    <b-icon icon="archive" v-if="isArchived()" />
                    {{ currentStudent.firstname }} {{ currentStudent.lastname }}
                </h1>
                <div class="row">
                    <div class="col-sm-6"><p>Mail: {{ currentStudent.user.email }}</p></div>
                    <div class="col-sm-6 text-right">
                        <div class="action-bar">

                            <b-button v-if="!isArchived()" variant="outline-primary" size="sm" @click="editStudent">
                                <b-icon icon="pencil"/>
                                Modifica profilo
                            </b-button>

                            <b-button v-if="(hasRole('admin') || hasRole('owner')) && isArchived()"
                                      variant="outline-danger"
                                      @click="unarchiveStudent"
                                      :disabled="this.$store.state.loading"> <b-icon icon="archive"/> Ripristina studente</b-button>
                        </div>
                    </div>
                </div>

                <hr/>

                <div v-if="hasRole('owner')">
                    <available-time-view
                        :student-id="currentStudent.id" />
                </div>

                <div>
                    <b-alert variant="success" :show="message != ''" fade>
                        <b-icon icon="hand-thumbs-up"/>
                        {{ message }}
                    </b-alert>
                    <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
                </div>
            </div>

            <activity-log-index :student-id="studentId" embed="true"/>
            <br>
            <attendance-index :student-id="studentId" />
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Student...' }}</p>
        </div>


        <div class="editor editor-edit" :class="{ 'opened': (edit) }">
            <div class="top-editor">
                <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
            </div>
            <student-edit v-if="currentStudent"
                          :student-id="currentStudent.id" :key="currentStudent.id"
                          v-on:updated-student="updatedStudent($event)"
                          v-on:deleted-student="deletedStudent()"
                          v-on:closed-editor="closeEditor()"/>
        </div>

    </div>
</template>

<script>
import StudentDataService from "@/components/student/StudentDataService";
import StudentEdit from "@/components/student/StudentEdit";
import AvailableTimeView from "@/components/available_time/AvailableTimeView";
import ActivityLogIndex from "@/components/activity_log/ActivityLogIndex";
import AttendanceIndex from "@/components/attendance/AttendanceIndex";
import {store} from "@/components/store";

export default {
    name: "student-view",
    components: {ActivityLogIndex, AvailableTimeView, StudentEdit, AttendanceIndex},
    data() {
        return {
            currentStudent: null,
            edit: false,
            message: '',
            errorMsg: ''
        };
    },
    props: ["studentId"],
    methods: {
        getStudent(id) {
            StudentDataService.get(id)
                .then(response => {
                    this.currentStudent = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });

        },
        closeEditor() {
            //this.$emit('closed-editor');
            this.edit = false;
        },
        editStudent() {
            this.edit = true;
        },
        unarchiveStudent() {
            StudentDataService.unarchive(this.currentStudent.id)
                .then(response => {
                    this.$emit('deleted-student');
                })
                .catch(e => {
                    console.log(e);
                });
        },
        isArchived() {
            return this.currentStudent.schools[0].profile_school.archived;
        },
        updatedStudent(student) {
            this.getStudent(this.studentId);
            this.$emit('updated-student', student);
            this.closeEditor();
        },
        deletedStudent() {
            this.$emit('deleted-student', this.currentStudent);
        }
    },
    mounted() {
        this.message = '';
        this.getStudent(this.studentId);
    }
};
</script>

<style scoped>
.editor-edit.opened {
    top: 20vh!important;
}
</style>