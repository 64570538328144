<template>
    <div>
        <div class="row section-header" >
                <div class="section-title" :class="(!showArchived) ? 'col-md-3' : 'col-md-5'">
                <h3 v-if="!showArchived">Allievi</h3>
                <h3 v-else>Allievi archiviati</h3>
            </div>

            <div :class="(!showArchived) ? 'col-md-6' : 'col-md-4'">
                <search-bar @search-name="searchName($event)"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <div class="row" >
                <div class="col-md-6">
                    <button class="btn btn-primary btn-add" @click="currentStudent = null; currentId = 0;" v-if="!showArchived">
                        <b-icon icon="plus"/>
                        Aggiungi Allievo
                    </button>
                </div>
                <div class="col-md-6 text-right">
                    <button class="btn btn-outline-secondary btn-sm" @click="toggleArchived">

                        <span v-if="!showArchived"><b-icon icon="archive"/> Mostra archiviati</span>
                        <span v-else>Ritorna agli allievi attivi</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId === -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>

                            <th scope="col" width="40"></th>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center">Stato</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': student.id == currentId }"
                            v-for="student in students"
                            :key="student.id"
                            @click="toggleActiveStudent(student)"
                        >

                            <td><img src="../../assets/student-avatar.jpg" class="rounded-circle" style="width: 30px">
                            </td>
                            <td>{{ student.firstname }} {{ student.lastname }}</td>
                            <td :class="{ 'text-success': (student.status), 'text-muted': !student.status }"
                                v-if="!showArchived"
                                class="text-center">
                                <b-icon :icon="(student.status) ? 'check2' : 'x-circle'"/>
                            </td>
                            <td v-else class="text-center text-muted">
                                <b-icon icon="archive"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.students.length == 0">
                        <span v-if="!showArchived">Ancora nessuno studente inserito</span>
                        <span v-else>Ancora nessuno studente archiviato</span>
                    </p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId > -1) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0 && currentStudent">
                    <student-view
                                  :student-id="currentStudent.id" :key="currentStudent.id"
                                  v-on:closed-editor="closeEditor()"
                                  v-on:updated-student="refreshCurrentStudent($event)"
                                  v-on:deleted-student="refreshList()"/>

                </div>
                <div v-else-if="currentId == 0">
                    <student-create
                        v-on:created-student="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>




        </div>


    </div>

</template>

<script>
import StudentDataService from "./StudentDataService";
import StudentCreate from "@/components/student/StudentCreate";
import StudentView from "@/components/student/StudentView";
import SearchBar from "@/components/layout/SearchBar";
import {store} from "@/components/store";

export default {
    name: "students-index",
    components: {StudentCreate, SearchBar, StudentView},
    data() {
        return {
            students: [],
            currentStudent: null,
            currentId: -1,
            showArchived: false
        };
    },
    methods: {
        toggleArchived() {
            this.showArchived = !this.showArchived
            this.currentStudent = null;
            this.currentId = -1;
            this.retrieveStudents();
        },
        retrieveStudents() {
            StudentDataService.getAll(this.showArchived)
                .then(response => {
                    this.students = response.data;
                    //student.schools[0].profile_school.confirmed
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveStudents();
            this.currentStudent = null;
            this.currentId = -1;
        },

        refreshCurrentStudent(student) {
            this.students.forEach(function (item) {
                if (item.id == student.id) {
                    item.firstname = student.firstname;
                    item.lastname = student.lastname;
                    item.status = student.status == 1;
                }
            });
            this.closeEditor();
            //this.currentStudent = student;
            //this.currentId = student.id;
        },

        setActiveStudent(student) {
            this.currentStudent = student;
            this.currentId = student.id;
        },

        unsetActiveStudent() {
            this.currentStudent = null;
            this.currentId = -1;
        },

        toggleActiveStudent(student) {
            if (this.currentId === student.id) {
                this.unsetActiveStudent()
            } else {
                this.setActiveStudent(student)
            }
        },

        searchName(name) {
            this.currentStudent = null;
            this.currentId = -1;
            StudentDataService.findByName(name, this.showArchived)
                .then(response => {
                    this.students = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            if(this.editStudent == true) {
                this.editStudent = false
                return;
            }
            this.currentStudent = null;
            this.currentId = -1;
        },

        canCreate() {
            return (this.hasRole('admin') || this.hasRole('owner'));
        },

    },
    mounted() {
        this.retrieveStudents();
    }
};
</script>

<style scoped>
.editor.opened {

}
</style>