<template>
    <div class="card">
        <h1>Aggiungi uno studente</h1>
        <div>
            <p>Aggiungi un utente già presente nella tua scuola con un altro ruolo</p>
            <profile-add role="student"
                         v-on:added-profile="addedStudent()"
                         v-on:closed-editor="closeEditor()" />
        </div>
        <hr>
        <div v-if="!submitted" >
            <h5>Crea un nuovo profilo</h5>
            <b-form @submit.stop.prevent="saveStudent">

                <b-form-group id="email" label="Email" label-for="email" description="">
                    <b-form-input
                        v-model="student.email"
                        placeholder=""
                        type="email"
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="email-live-feedback"
                    >Questo campo è obbligatorio e deve essere un'email valida.</b-form-invalid-feedback>
                </b-form-group>

                <div v-if="state == 'ProfileFound'">
                    <p>Abbiamo trovato uno profilo associato a questa email nel nostro portale<br/>
                        Clicca sul bottone <b>aggiungi</b> per inserirlo nella tua scuola.<br/>
                        <b>L'utente riceverà una mail per confermare l'iscrizione</b>.</p>
                    <profile-found-view :profile="student" />
                </div>

                <div v-if="state == 'ProfileFoundInSchoolNotConfirmed'">
                    <p>Il profilo è già stato aggiunto come studente nella tua scuola, ma non ha ancora confermato.<br/>
                        Clicca sul bottone <b>Re invia email</b> per inviare di nuovo la mail di conferma.</p>
                    <profile-found-view :profile="student" />
                </div>

                <div v-if="state == 'ProfileFoundInSchool'">
                    <p><b>{{ student.firstname }} {{ student.lastname }}</b> è già presente nella scuola con un altro ruolo, clicca il bottone per aggiungerlo a questo ruolo.</p>
                </div>

                <div v-if="state == 'ProfileNotFound'">
                    <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                        <b-form-input
                            v-model="student.firstname"
                            placeholder=""
                            :state="validateState('firstname')"
                            aria-describedby="firstname-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="firstname-live-feedback"
                        >Questo campo è obbligatorio e deve contenere almeno 3 caratteri.</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                        <b-form-input
                            v-model="student.lastname"
                            placeholder=""
                            :state="validateState('lastname')"
                            aria-describedby="lastname-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="lastname-live-feedback"
                        >Questo campo è obbligatorio e deve contenere almeno 3 caratteri.</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="birthDate" label="Data di nascita" label-for="birthDate" description="">
                        <b-form-input
                            type="date"
                            v-model="student.birthDate"
                            placeholder=""
                            :state="validateState('birthDate')"
                            aria-describedby="lastname-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="birthDate-live-feedback"
                        >Questo campo è obbligatorio e deve contenere una data in formato ...</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="password" label="Password" label-for="password" description="">
                        <b-form-input
                            v-model="student.password"
                            placeholder=""
                            type="text"
                            :state="validateState('password')"
                            aria-describedby="password-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="password-live-feedback"
                        >Questo campo è obbligatorio.</b-form-invalid-feedback>
                    </b-form-group>

                    Confermato: <toggle-button v-model="student.confirmed" :height="25"/>

                </div>


            </b-form>


            <div class="action-bar row">
                <div class="col-sm-8">
                    <b-button variant="success" type="button" @click="findStudent()" v-if="state == 'None'">Continua</b-button>
                    <b-button variant="success" type="button" @click="resendConfirmMail()" :disabled="this.$store.state.loading" v-if="state == 'ProfileFoundInSchoolNotConfirmed'">Re invia email</b-button>
                    <button class="btn btn-success " @click="saveStudent" :disabled="this.$store.state.loading" v-if="state != 'None' && state != 'ProfileFoundInSchoolNotConfirmed'">Aggiungi</button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import StudentDataService from "./StudentDataService";
import ProfileDataService from "@/components/profile/ProfileDataService";
import { required, minLength, email } from 'vuelidate/lib/validators';
import ProfileFoundView from "@/components/profile/ProfileFoundView";
import ProfileAdd from "@/components/profile/ProfileAdd";

let successEvent = new Event('onSuccessMsg');

export default {
    name: "student-create",
    components: {ProfileAdd, ProfileFoundView},
    data() {
        return {
            student: {
                id: null,
                firstname: "",
                lastname: "",
                birthDate: "",
                email: "",
                password: "",
                active: true,
                confirmed: false
            },
            state: 'None', // None, ProfileNotFound, ProfileFound, ProfileFoundInSchool
            message: '',
            errorMsg: '',
            submitted: false
        };
    },
    validations: {
        student: {
            firstname: {
                required,
                minLength: minLength(4)
            },
            lastname: {
                required,
                minLength: minLength(4)
            },
            birthDate: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(4)
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.student[name];
            return $dirty ? !$error : null;
        },
        saveStudent() {
            switch (this.state) {
                case 'ProfileNotFound':
                    this.createStudent();
                    break;
                case 'ProfileFoundInSchool':
                    this.addStudent();
                    break;
                case 'ProfileFound':
                    this.sendRequestStudent();
                    break;
                default:
                    break;
            }
        },
        createStudent() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                StudentDataService.create(this.student)
                    .then(response => {
                        if (this.student.confirmed) successEvent.message = "L'utente è stato aggiunto alla tua scuola";
                        else successEvent.message = "Abbiamo inviato l'email di conferma all'indirizzo indicato. Quando l'utente completerà la procedura, potrai vederlo nell'elenco.";
                        document.dispatchEvent(successEvent);
                        this.student.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-student');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        addStudent() {
            ProfileDataService.addRole(this.student.id, 'student', this.$store.state.schoolId)
                .then(response => {
                    this.submitted = true;
                    this.$emit('created-student');
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },
        sendRequestStudent() {
            ProfileDataService.addRole(this.student.id, 'student', this.$store.state.schoolId, false)
                .then(response => {
                    successEvent.message = "Abbiamo inviato l'email di conferma all'indirizzo indicato. Quando l'utente completerà la procedura, potrai vederlo nell'elenco.";
                    document.dispatchEvent(successEvent);
                    this.submitted = true;
                    this.$emit('created-student');
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },
        resendConfirmMail() {
            ProfileDataService.resendConfirmMail(this.student.id, 'student', this.$store.state.schoolId)
                .then(response => {
                    successEvent.message = "Abbiamo inviato l'email di conferma all'indirizzo indicato. Quando l'utente completerà la procedura, potrai vederlo nell'elenco.";
                    document.dispatchEvent(successEvent);
                    this.submitted = true;
                    this.$emit('created-student');
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },
        findStudent() {
            const schoolId = this.$store.state.schoolId;
            ProfileDataService.getByEmail(this.student.email)
                .then(response => {
                    this.state = 'ProfileFound';
                    // if student is already added to current school return
                    if (response.data.schools) {
                        if (response.data.schools.find(s => s.id == schoolId && s.profile_school.role == 'student' && s.confirmed)) {
                            this.state = 'None';
                            alert('lo studente è già presente nella tua scuola');
                            return;
                        }
                        if (response.data.schools.find(s => s.id == schoolId && s.profile_school.role == 'student' && !s.confirmed)) {
                            this.state = 'ProfileFoundInSchoolNotConfirmed';
                        } else if (response.data.schools.find(s => s.id == schoolId)) {
                            this.state = 'ProfileFoundInSchool';
                        }
                    }
                    // otherwise continue
                    this.student.id = response.data.id;
                    this.student.firstname = response.data.firstname;
                    this.student.lastname = response.data.lastname;
                    this.student.birthDate = response.data.birthDate;
                    this.student.active = response.data.active;
                    console.log(this.state)
                })
                .catch(e => {
                    if (e.response.status == 400) this.state = 'ProfileNotFound';
                    else this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e.response);
                });
        },
        resetStudent() {
            this.student.id = null;
            this.student.firstname = "";
            this.student.lastname = "";
            this.student.birthDate = "";
            this.student.active = true;
            this.state = 'None';
        },
        addedStudent() {
            this.$emit('created-student');
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    watch: {
        'student.email': function (value) {
            if (value == '') this.resetStudent();
        },
    },
    mounted() {

    }
};
</script>

<style>

</style>
