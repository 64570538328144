<template>
    <div v-if="currentStudent">
        <div class="card">
            <b-form @submit="updateStudent">

                <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                    <!-- <b-form-input v-model="currentStudent.firstname" placeholder="" :state="validate('firstname')"></b-form-input> -->
                    <b-form-input v-model="currentStudent.firstname" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                    <b-form-input v-model="currentStudent.lastname" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="birthDate" label="Data di nascita" label-for="birthDate" description="">
                    <b-form-input v-model="currentStudent.birthDate" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="email" label="Email" label-for="email" description="">
                    <b-form-input v-model="currentStudent.email" placeholder="" type="email" disabled></b-form-input>
                </b-form-group>

                <b-form-group id="password" label="Password" label-for="password" description="Compila il campo solo se vuoi cambiare la password dell'utente, oppure lascialo vuoto.">
                    <b-form-input v-model="currentStudent.password" placeholder="" type="password"></b-form-input>
                </b-form-group>

                Attivo: <toggle-button v-model="currentStudent.status" :height="25"/>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-5">
                    <button class="btn btn-success " @click="updateStudent" :disabled="this.$store.state.loading">
                        <b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-7 text-right">
                    <b-button variant="danger"
                              v-if="(hasRole('admin') || hasRole('owner'))"
                              @click="removeStudent"
                              :disabled="this.$store.state.loading"> <b-icon icon="trash"/> Rimuovi dal ruolo</b-button>
                    &nbsp;
                    <b-button variant="outline-danger"
                              v-if="(hasRole('admin') || hasRole('owner')) && !isArchived()"
                              @click="archiveStudent"
                              :disabled="this.$store.state.loading"> <b-icon icon="archive"/> Archivia</b-button>

                    <b-button variant="outline-danger"
                              v-if="(hasRole('admin') || hasRole('owner')) && isArchived()"
                              @click="unarchiveStudent"
                              :disabled="this.$store.state.loading"> <b-icon icon="archive"/> Ripristina studente</b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>

    <div v-else>
        <br/>
        <p>{{ (errorMsg) ? errorMsg : 'Carico...' }}</p>
    </div>
</template>

<script>
import StudentDataService from "./StudentDataService";
import ProfileDataService from "@/components/profile/ProfileDataService";


export default {
    name: "student-edit",
    data() {
        return {
            currentStudent: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ["studentId"],
    methods: {
        getStudent(id) {
            StudentDataService.get(id)
                .then(response => {
                    this.currentStudent = response.data;
                    this.currentStudent.email = response.data.user.email;
                    this.currentStudent.status = (this.currentStudent.status == 1);


                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        validate(name) {
            return this.currentStudent[name] != '';
        },
        updateStudent() {
            StudentDataService.update(this.currentStudent.id, this.currentStudent)
                .then(response => {
                    this.message = 'Informazioni salvate con successo!';
                    this.$emit('updated-student', this.currentStudent);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        archiveStudent() {
            if(confirm(`Vuoi archiviare l'allievo ${this.currentStudent.firstname} ${this.currentStudent.lastname}?`)) {
                StudentDataService.archive(this.currentStudent.id)
                    .then(response => {
                        this.$emit('deleted-student');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        unarchiveStudent() {
            StudentDataService.unarchive(this.currentStudent.id)
                .then(response => {
                    this.$emit('deleted-student');
                })
                .catch(e => {
                    console.log(e);
                });
        },
        isArchived() {
            return this.currentStudent.schools[0].profile_school.archived;
        },
        removeStudent() {
            if(confirm(`Vuoi rimuovere lo studente ${this.currentStudent.firstname} ${this.currentStudent.lastname}? Il profilo sarà comunque disponibile`)) {
                ProfileDataService.removeRole(this.currentStudent.id, 'student')
                    .then(response => {
                        this.$emit('deleted-student');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },
        deleteStudent() {
            if(confirm(`Vuoi cancellare l'allievo ${this.currentStudent.firstname} ${this.currentStudent.lastname}?`)) {
                StudentDataService.delete(this.currentStudent.id)
                    .then(response => {
                        this.$emit('deleted-student');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getStudent(this.studentId);
    }
};
</script>


